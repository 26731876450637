import React from "react";
import * as Chakra from "@chakra-ui/react";
import { Link, PageProps } from "gatsby";
import { Seo } from "../../components/seo";
import NextPageButton from "../../components/next-page-button";
import { StaticImage } from "gatsby-plugin-image";

const Start2 = ({ location }: PageProps): React.ReactElement => (
  <Chakra.Box width="100%">
    <Seo title="Start" location={location} />

    <Chakra.Flex
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <Chakra.Flex
        flexDirection="row-reverse"
        maxWidth="908px"
        py="40px"
        px="40px"
        justifyContent="space-between"
        alignItems={{ base: "start", lg: "center" }}
        height="100%"
        m="auto"
        width="100%"
        position="relative"
      >
        <Chakra.Box maxWidth="354px">
          <Chakra.Text
            as="h4"
            pb="30px"
            fontSize="28px"
            letterSpacing="0.22px"
            lineHeight="20px"
            fontWeight="600"
          >
            Complicating factors
          </Chakra.Text>
          <Chakra.VStack
            textAlign="left"
            alignItems="start"
            justifyContent="start"
            spacing="20px"
            fontSize="14px"
            letterSpacing="0.11px"
            lineHeight="20px"
            fontWeight="300"
          >
            <p>
              These widely used approaches are not suited for discovery and
              exploration. It takes many tests  and iterations to establish
              market fit by understanding what customers value, their
              preferences and their drivers.
            </p>
            <p>
              In the absence of facts and data from customers, business leaders
              are often required to make important strategic decisions based on
              their past experiences, personal beliefs or what feels right.
            </p>
          </Chakra.VStack>
        </Chakra.Box>
        <Chakra.Box display={{ base: "none", lg: "unset" }}>
          <StaticImage
            src="../../images/start-slides/start-slide-2.png"
            alt=""
          />
        </Chakra.Box>
      </Chakra.Flex>
    </Chakra.Flex>
    <NextPageButton as={Link} to="/start/3">
      Next
    </NextPageButton>
  </Chakra.Box>
);

export default Start2;
