import React from "react";
import * as Chakra from "@chakra-ui/react";
import { darken } from "@chakra-ui/theme-tools";

interface NextPageButtonProps extends Chakra.ButtonProps {
  to?: string;
}

const NextPageButton = (props: NextPageButtonProps): React.ReactElement => {
  return (
    <Chakra.Box position="fixed" bottom={{base: "0", lg: "30px"}} right={{base: "0", lg: "30px"}}>
      <Chakra.Box width="100%">
        <Chakra.Button
          fontWeight="400"
          borderRadius="0"
          _hover={{ backgroundColor: darken("#E6B822", 5) }}
          _active={{ backgroundColor: darken("#E6B822", 5) }}
          _focus={{ backgroundColor: darken("#E6B822", 5) }}
          transition="0.2s"
          bgColor="#E6B822"
          width="155px"
          height="44px"
          {...props}
        />
      </Chakra.Box>
    </Chakra.Box>
  );
};

export default NextPageButton;
